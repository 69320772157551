<template>
  <div class="navbar">
    <div class="container flex flex-direction-column">
      <h1 class="logo">
        <a href="/"
          ><img class="index-logo" src="../assets/img/euro-logo.png" alt=""
        /></a>
      </h1>
      <nav>
        <ul>
          <li :class="active_class == '/' ? 'active' : ''">
            <a :href="`/${i18n.locale}`">{{ $t("nav.home") }}</a>
          </li>
          <li :class="active_class == '/recommend' ? 'active' : ''">
            <a :href="`/${i18n.locale}/recommend`">{{ $t("nav.recom") }}</a>
          </li>
          <li :class="active_class == '/statistics' ? 'active' : ''">
            <a :href="`/${i18n.locale}/statistics`">{{
              $t("nav.statistics")
            }}</a>
          </li>
          <li :class="active_class == '/experience' ? 'active' : ''">
            <a :href="`/${i18n.locale}/experience`">{{
              $t("nav.experience")
            }}</a>
          </li>
          <li :class="active_class == '/download-app' ? '' : ''">
            <a :href="`/${i18n.locale}/download-app`">{{
              $t("nav.download-app")
            }}</a>
          </li>
          <li style="padding: 35px">
            <div class="dropdown-wrap">
              <div class="dropdown">
                <button @click="myFunction()" class="dropbtn">
                  <img
                    :src="
                      require('../assets/img/lang-' +
                        currentLocaleList.flag +
                        '.png')
                    "
                    alt=""
                    class="flag"
                  />
                  {{ currentLocaleList.text }}
                </button>

                <div id="myDropdown" class="dropdown-content">
                  <template v-for="(item, index) in localList">
                    <a
                      v-show="item.id != currentLocale"
                      href=""
                      :key="index"
                      :class="item.id == currentLocale ? 'active' : ''"
                      @click.prevent="setLocale(item.id)"
                      ><img
                        :src="
                          require('../assets/img/lang-' + item.flag + '.png')
                        "
                        alt=""
                        class="flag"
                      />
                      {{ item.text }}</a
                    >
                  </template>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import i18n from "../i18n";
export default {
  name: "Header",
  data() {
    return {
      i18n,
      currentLocale: "id",
      currentLocaleList: {
        id: "id",
        flag: "idn",
        text: "Indon",
      },
      active_class: "",
      ishow: true,
      isShowDownload: true,
      matchSetting: null,
      localList: [
        {
          id: "id",
          flag: "idn",
          text: "Indonesia",
        },
        {
          id: "vi",
          flag: "vi",
          text: "Việt Nam",
        },
        // ,
        // {
        //   id: "en",
        //   flag: "en",
        //   text: "English",
        // },
        // {
        //   id: "cn",
        //   flag: "cn",
        //   text: "中文",
        // },
      ],
      currentHost: "https://72963.com",
    };
  },

  mounted() {
    this.active_class = window.location.pathname.split("/")[2];
    var currentLocale = window.location.pathname.split("/")[1];

    if (localStorage.getItem("lang") != null) {
      currentLocale = localStorage.getItem("lang");
      this.$router.push({
        params: { lang: currentLocale },
      });
    }

    this.$i18n.locale = currentLocale;
    this.currentLocale = currentLocale;
    this.currentLocaleList = this.localList.filter(function (item) {
      return item.id == currentLocale;
    })[0];

    // Close the dropdown if the user clicks outside of it
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
  },
  methods: {
    myFunction() {
      document.getElementById("myDropdown").classList.toggle("show");
    },
    setLocale(locale) {
      this.i18n.locale = locale;
      this.currentLocale = locale;
      this.currentLocaleList = this.localList.filter(function (item) {
        return item.id == locale;
      })[0];
      this.$router.push({
        params: { lang: locale },
      });

      localStorage.setItem("lang", locale);
      location.reload();
    },
  },
};
</script>

<style scoped>
.navbar a {
  font-size: 20px !important;
}
.dropdown-wrap {
  position: fixed;
}
.flag {
  width: 20px;
  height: 20px;
}
.dropbtn {
  background-color: #3498db;
  color: white;
  text-align: left;
  border-radius: 5%;
  padding: 10px 15px 10px 15px;
  width: 125px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  width: 125px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: black;
  height: 50px;
  padding: 10px 15px 10px 15px;
  text-decoration: none;
  text-align: left;
  display: block;
  font-size: 16px !important;
}

.dropdown-content a.active {
  background-color: #2980b9;
  color: white;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
</style>